import React from 'react';
import Obfuscate from 'react-obfuscate';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Content from '../components/Content';

const ContactStyles = styled.div`
  margin: 0 auto;
  max-width: 660px;

  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: var(--red);
    }
  }
`;

export default function Contact() {
  return (
    <Layout page="Contact">
      <Content>
        <ContactStyles>
          <p>Got projects you need help with?</p>
          <p>Got a question about words?</p>
          <p>Simply want to have a chat?</p>
          <br />
          <p>
            Send me an email at{' '}
            <span style={{ textDecoration: 'underline' }}>
              <Obfuscate email="kate.j.langham@gmail.com" />
            </span>
          </p>
          <p>
            Or give me a ring on{' '}
            <nobr>
              <Obfuscate tel="+447462 102 395" />
            </nobr>
          </p>
        </ContactStyles>
      </Content>
    </Layout>
  );
}
